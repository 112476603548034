// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start();
// require("@rails/activestorage").start();
// require("channels");

import "./application.css"
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { createApp } from 'vue';
import 'tippy.js/dist/tippy.css';

import Flash from '../flash.vue';

document.addEventListener('DOMContentLoaded', () => {
  createApp({ components: { Flash } }).mount('.notifications');

  const dropdowns = document.querySelectorAll('.dropdown:not(.is-hoverable)');
  if (dropdowns.length > 0) {
    dropdowns.forEach(function (el) {
      const button = el.querySelector('button');
      button.addEventListener("click", function (event) {
        event.stopPropagation();
        el.classList.toggle("is-active");
      });
    });

    document.addEventListener("click", function (event) {
      dropdowns.forEach(function (el) {
        el.classList.remove("is-active");
      });
    });
  }
});
