<template>
    <div class="notification" v-bind:class="notficationStyle" v-show="display">
        <button class="delete" @click="display = false"></button>
        {{message}}
    </div>
</template>

<script>
export default {
  data() {
    return {
      display: true
    };
  },
  computed: {
    notficationStyle() {
      if (this.$props.type == 'error') {
        return 'is-danger';
      }
      return "is-info";
    }
  },
  props: {
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'notice'
    }
  }
};
</script>
